import React, { FC, useContext, useRef, useState, WheelEvent } from 'react';
import { graphql, navigate, PageProps } from 'gatsby';
import { motion, Variants } from 'framer-motion';
import { throttle, debounce } from 'throttle-debounce';
import {
    ContentfulCategoryMoviesConnection,
    ContentfulCategoryServices,
    ContentfulCategoryServicesConnection,
    ContentfulMovie,
    ContentfulService,
} from '../model/generated/graphql';
import LocaleContext from '../context/Locale';
import { Section } from '../modules/movies/components/Section';
import { MovieNav } from '../modules/movies/components/MovieNav';
import { DotsIndicator } from '../modules/movies/components/DotsIndicator';
import { Notch } from '../components/Notch';
import { useDimensions } from '../hooks/useDimenstions';

type StudioQueryProps = {
    contentfulService: ContentfulService;
    allContentfulCategoryMovies: ContentfulCategoryServicesConnection;
};
type StudioPageProps = PageProps<StudioQueryProps>;

const transition = {
    duration: 1,
    ease: [0.43, 0.13, 0.23, 0.96],
};

export const StudioTemplate: FC<StudioPageProps> = ({ data }) => {
    const { activeLocale } = useContext(LocaleContext);

    return (
        <motion.div initial="initial" animate="animate" exit="exit" key="movieEntry2">
            {data.contentfulService.title}
        </motion.div>
    );
};

export default StudioTemplate;

export const pageQuery = graphql`
    query ServiceBySlug($node_locale: String, $slug: String) {
        contentfulService(node_locale: { eq: $node_locale }, slug: { eq: $slug }) {
            id
            title
        }
        allContentfulCategoryMovies(filter: { node_locale: { eq: $node_locale } }) {
            nodes {
                id
                title
                movies {
                    id
                    title
                    slug
                    movieInfo {
                        releaseDate
                    }
                    status
                    cover {
                        gatsbyImageData(
                            layout: CONSTRAINED
                            width: 600
                            height: 280
                            cropFocus: CENTER
                            quality: 90
                            formats: [AUTO, WEBP]
                            placeholder: BLURRED
                        )
                    }
                }
            }
        }
    }
`;
